<template>
  <div>
    <ledger5Logo />

    <v-card flat width="550" class="mx-auto mt-4">
      <v-card-title>
        <span class="primary--text display-1 ml-3 mt-2 mb-2"
          >Check your Mailbox</span
        >
        <h1 class="display-1">
          <v-icon right large color="primary" class="pb-1">mdi-email</v-icon>
        </h1>
        <v-progress-linear
          v-if="showProgressBar"
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="2" />

            <v-col cols="8" class="mt-4">
              <v-card-text class="primary--text font-weight-medium ml-7 mt-n8">
                Enter the code you received via E-Mail and set a new password
              </v-card-text>
              <v-text-field
                rounded
                v-model="email"
                :rules="emailRules"
                validate-on-blur
                label="Username (E-Mail)"
                prepend-icon="mdi-account-circle"
                required
                outlined
                dense
              />
              <v-text-field
                rounded
                v-model="code"
                :rules="numberRules"
                validate-on-blur
                label="Verification Code"
                prepend-icon="mdi-alpha-c-circle"
                outlined
                dense
              />
              <v-text-field
                rounded
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                validate-on-blur
                prepend-icon="mdi-lock"
                label="new Password"
                @click:append="showPassword = !showPassword"
                required
                outlined
                dense
              />
              <v-text-field
                rounded
                v-model="rePassword"
                :type="showRePassword ? 'text' : 'password'"
                :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordConfirmationRule]"
                validate-on-blur
                prepend-icon="mdi-lock"
                label="Confirm new Password"
                @click:append="showRePassword = !showRePassword"
                required
                outlined
                dense
              />
              <div class="text-center">
                <v-btn
                  rounded
                  depressed
                  color="success text--white"
                  @click="resetPw"
                  width="304"
                  class="ml-8 mt-4 mb-6"
                  >Verify</v-btn
                >
              </div>
            </v-col>
            <v-col cols="2" />
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import ledger5Logo from '@/components/Ledger5Logo.vue'

export default {
  name: 'ResetPassword',

  components: {
    ledger5Logo
  },

  data() {
    return {
      showProgressBar: false,
      valid: true,
      email: this.$route.query.email,
      code: '',
      password: '',
      rePassword: '',
      showPassword: false,
      showRePassword: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      numberRules: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v =>
          (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(v) && v.length >= 8) ||
          'Uppercase, lowercase, Number and at least 8 Characters req.'
      ]
    }
  },

  methods: {
    async resetPw() {
      if (this.$refs.form.validate()) {
        // validate form and enable button
        this.showProgressBar = true
        Auth.forgotPasswordSubmit(this.email, this.code, this.password)
          .then(data => {
            this.showProgressBar = false
            console.log(data)
            this.$router.push({ path: '/login', query: { email: this.email } })
          })
          .catch(err => {
            this.showProgressBar = false
            this.$refs.form.reset() // Reset form to enable the user to enter new data
            console.log(err)
            const notification = {
              type: 'error',
              message: err.message
            }
            this.$store.dispatch('addNotification', notification)
          })
      }
    }
  },

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || 'Passwords do not match'
    }
  }
}
</script>

<style lang="scss" scoped></style>
